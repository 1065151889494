<template>
        <Layout ref="layout">
            <Encrypt :item="this.item"/>
            <Decrypt :item="this.item"/>
            <ModalShare/>
            <Move/>
            <Copy/>
            <CopyPersonal/>
            <CopyWorkgroup/>
            <Share/>
            <Download/>
            <ImageView/>
            <VideoView/>
            <AudioView/>
            <toTrash/>
            <div class="search-page">
                <div class="d-flex justify-content-between align-items-center">
                    <p class="title-search-v3">{{ listings.length }} result</p>
                    <p v-if="selectedField.length > 0" class="title-search-v3">{{ selectedField.length }} Selected</p>
                </div>
                <div v-if="listings.length > 0">
                    <div class="mb-3 d-flex justify-content-between align-items-center">
                        <b-dropdown v-if="selectedField.length < 2" id="select-type-dropdown">
                            <template #button-content>
                                <div class="d-flex align-items-center">
                                    <img src="@/assets/images/icon/chevDown.svg" alt="">
                                    <span class="title-invite-wg-v3 ml-1">{{ selectedType.label }}</span>
                                </div>
                            </template>
                            <b-dropdown-item v-for="(type,ia) in itemTypes" :key="ia" @click="changeType(ia)">
                                <div class="d-flex justify-content-start">
                                    <img v-if="type.selected" src="@/assets/images/icon/Checklist.svg" alt="">
                                    <span v-else class="mx-2"></span>
                                    <span class="title-invite-wg-v3 mx-3">{{ type.label }}</span>
                                </div>
                            </b-dropdown-item>
                        </b-dropdown>
                        <div v-else>
                            <button @click="deleteMultiple()" class="btn-multiple-search">Delete</button>
                        </div>
                        <div>
                            <div class="mr-3">
                                <b-button variant="outline-secondary" @click="refresh()" class="mr-4 btn-refresh hover-blue  d-none d-lg-block">
                                    <img src="@/assets/images/icon/fi-rr-refresh.svg" class="pb-1" alt height="100%"/>
                                    <span class="ml-2 default-text-style">Refresh</span>
                                </b-button>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 all-pages pl-0">
                        <div class="page-title-box d-flex align-items-center justify-content-between">
                            <table class="table table-storage default-text-style " sticky-header>
                                <thead>
                                    <th width="2%">
                                        <div class="d-flex justify-content-center align-items-center">
                                        <b-form-checkbox
                                            v-if="state == 'workgroup' && selectedWorkgroup.type != 'PRIVATE'"
                                            id="checkbox-1"
                                            name="checkbox-1"
                                            :class="allchecked == true ? 'more-btn-op-1' :'more-btn'"
                                            :value="true"
                                            v-model="allchecked"
                                            :unchecked-value="false"
                                            @change="changeChecked()"
                                        >
                                        </b-form-checkbox>  
                                        <b-form-checkbox
                                            v-else-if="state == 'storage'"
                                            id="checkbox-1"
                                            name="checkbox-1"
                                            :class="allchecked == true ? 'more-btn-op-1' :'more-btn'"
                                            :value="true"
                                            v-model="allchecked"
                                            :unchecked-value="false"
                                            @change="changeChecked()"
                                        >
                                        </b-form-checkbox>  

                                        </div>
                                    </th>
                                    <th width="55%" class="default-text-style name-column" @click="sorting()">
                                        <div class="d-flex">
                                        <!-- <b-form-checkbox
                                            id="checkbox-1"
                                            name="checkbox-1"
                                            class="more-btn"
                                            :value="true"
                                            v-model="allchecked"
                                            :unchecked-value="false"
                                            @change="changeChecked()"
                                        >
                                        </b-form-checkbox>   -->
                                        <span class="text-muted">Name </span>
                                        <img v-if="sort == 'ASC'" src="@/assets/images/icon/Chevron-Down.svg" alt="" class="ml-1 rotate-180">
                                        <img v-else src="@/assets/images/icon/Chevron-Down.svg" alt="" class="ml-1">
                                        </div>
                                    </th>
                                    <!-- <th width="14%" class="default-text-style table-web-display"><span class="text-muted"></span></th> -->
                                    <th width="23%" class="default-text-style table-web-display"><span class="text-muted">Last Modified</span></th>
                                    <th width="20%" class="default-text-style table-web-display"><span class="text-muted">File Size</span></th>
                                    <th width="2%" class="text-center"></th>
                                </thead>
                                <Sekeleton v-if="loading"/>
                                <tbody v-if="listings.length > 0 && !loading && state != 'storage' && selectedWorkgroup.type == 'PRIVATE'">
                                    <!-- {{listings}} -->
                                    <!-- {{ state }} -->
                                    <tr class="item-table" v-for="(list,index) in listings" :key="index" :id="index+'item'" :class="list.checked ? 'active' : ''"
                                    v-on:click.ctrl="!list.isLock ? ctrlClick(list) : ''"
                                    >
                                    <!-- {{list}} -->
                                        <td>
                                            <b-form-checkbox
                                                v-if="!list.isLock"
                                                :id="index+'checkbox'"
                                                :name="index+'checkbox'"
                                                :class="list.checked == true ? 'more-btn-op-1' : 'more-btn'"
                                                :value="true"
                                                v-model="list.checked"
                                                :unchecked-value="false"
                                                @change="checked(list)"
                                            >
                                            </b-form-checkbox> 
                                        </td>
                                        <td class="align-items-center name-column"
                                            @click.exact="!list.isLock ? ctrlClick(list) : ''"
                                        >
                                            <div class="td-file" >
                                            <div class="d-flex align-items-center py-2">
                                                <!-- <b-form-checkbox
                                                :id="index+'checkbox'"
                                                :name="index+'checkbox'"
                                                class="more-btn"
                                                :value="true"
                                                v-model="list.checked"
                                                :unchecked-value="false"
                                                @change="checked(list)"
                                                >
                                                </b-form-checkbox>   -->
                                                <div class="d-flex ">
                                                <div class="file-name">
                                                    <!-- {{limit}} {{total}} -->
                                                    <!-- <img v-if="list.isShare" :src="require(`@/assets/images/icon/shared-icon.svg`)" alt> -->
                                                    <img v-if="list.encrypt == 2 || list.encrypt == 7 || list.encrypt == 4" :src="require(`@/assets/images/icon/lock-encrypt.svg`)" alt height="24px"/>
                                                    <img v-else :src="require(`@/assets/images/icon/${list.icon}`)" alt height="24px" :class="list.isLock ? 'change-opacity' : ''"/>
                                                    <span v-if="!list.isEdit"  v-b-tooltip.hover.ds1000.top="list.file_name" :class="list.isLock ? 'change-opacity' : ''" class="mx-2">{{list.file_name}}</span>
                                                    <b-form v-else >
                                                        <b-form-group id="input-group-1" class="form-group-rename" label-class="label-input" label-for="input-1">
                                                            <b-input-group>
                                                                <!-- <b-input-group-append>
                                                                    <img :src="require(`@/assets/images/icon/${list.icon}`)" alt />
                                                                </b-input-group-append> -->
                                                                <b-form-input id="input-1" autofocus class="login-input-style" v-model="valueRename" type="text" placeholder="Folder New"></b-form-input>
                                                                <b-input-group-append>
                                                                    <b-button @click="submitRename(index)" class="btn-on-input" v-b-tooltip.hover title="Save" ><i class="dripicons-checkmark text-success"></i></b-button>
                                                                    <b-button @click="rename(index)" class="btn-on-input" v-b-tooltip.hover title="Cancel" ><i class="dripicons-cross text-danger"></i></b-button>
                                                                </b-input-group-append>
                                                            </b-input-group>
                                                        </b-form-group>
                                                    </b-form>
                                                    <!-- <img v-if="list.isShare" :src="require(`@/assets/images/icon/shared-icon.svg`)" alt data-toggle="tooltip" title="This file is shared"> -->
                                                </div>
                                                <!-- <img v-if="list.isShare" :src="require(`@/assets/images/icon/shared-icon.svg`)" alt> -->
                                                <!-- <span v-if="list.encrypt == 2 || list.encrypt == 7 || list.encrypt == 4" class="ml-3 encrypt-text">Encrypted</span> -->
                                                </div>
                                            </div>
                                                <div class="icon-status-file">
                                                <img v-if="list.isShare && state == 'storage'" :src="require(`@/assets/images/icon/shared-file.svg`)" alt v-b-tooltip.hover title="This file is shared" class="ml-2">
                                                <!-- <img v-if="list.isShare" :src="require(`@/assets/images/icon/shared-file.svg`)" alt data-toggle="tooltip" title="This file is shared" class="ml-2"> -->
                                                <!-- <img v-if="list.encrypt == 2 || list.encrypt == 7" :src="require(`@/assets/images/icon/encrypted-file.svg`)" alt data-toggle="tooltip" title="This file is encrypted" class="ml-2"> -->
                                                <img v-if="list.encrypt == 2 || list.encrypt == 7 || list.encrypt == 4" :src="require(`@/assets/images/icon/encrypted-file.svg`)" alt v-b-tooltip.hover title="This file is encrypted" class="ml-2">
                                                </div>
                                            </div>
                                        </td>
                                        <!-- <td class="table-web-display">
                                        <p><span>Encrypt</span></p>
                                        </td> -->
                                        <td @click.exact="!list.isLock ? ctrlClick(list) : ''" :class="list.isLock ? 'change-opacity' : ''" class="table-web-display">
                                            <p> {{list.datemodified}} </p>
                                        </td>
                                        <td @click.exact="!list.isLock ? ctrlClick(list) : ''" :class="list.isLock ? 'change-opacity' : ''" class="table-web-display">
                                            <p>{{list.size}}</p>
                                        </td>
                                        <td>
                                            <div v-if="canAccess && !list.isLock"
                                                :class="list.checked == true ? 'more-btn-op-1' : 'more-btn'"
                                            >
                                                <b-dropdown id="dropdown-home" dropright class="bg-transparent more-menu">
                                                    <template #button-content>
                                                        <img src="@/assets/images/icon/More.png" @click.exact="clickers(list)" alt />
                                                    </template>
                                                    <b-dropdown-item-button v-if="(list.encrypt != 2) && (list.encrypt != 7)" @click="openFile(list)">
                                                        <img src="@/assets/images/icon/fi-rr-expand.svg" alt height="100%" />
                                                        <span class="ml-3 default-text-style">Open</span>
                                                    </b-dropdown-item-button>
                                                    <!-- <b-dropdown-item-button v-if="(!list.isFolder && list.encrypt != 2 && list.encrypt != 7 && list.encrypt != 4) && isOwner" @click="sendInfoEncrypt(list)">
                                                        <img src="@/assets/images/icon/fi-rr-shield-check.svg" alt height="100%" />
                                                        <span class="ml-3 default-text-style">Encrypt</span>
                                                    </b-dropdown-item-button> -->
                                                    <!-- <b-dropdown-item-button v-if="(!list.isFolder && list.encrypt == 2 || list.encrypt == 7) && isOwner" @click="sendInfoDecrypt(list)">
                                                        <img src="@/assets/images/icon/fi-rr-shield-cross.svg" alt height="100%" />
                                                        <span class="ml-3 default-text-style">Decrypt</span>
                                                    </b-dropdown-item-button> -->
                                                    <!-- <b-dropdown-item-button v-if="(!list.isFolder && list.encrypt != 2 && list.encrypt != 7 && list.encrypt != 4 && state != 'workgroup')" @click="shareopennew(list)">
                                                        <img src="@/assets/images/icon/fi-rr-redo.svg" alt height="100%" />
                                                        <span class="ml-3  default-text-style">Share</span>
                                                    </b-dropdown-item-button> -->
                                                    <!-- <b-dropdown-item-button v-if="(!list.isFolder && list.encrypt != 2 && list.encrypt != 7 && list.encrypt != 4)" @click="shareopen(list)">
                                                        <img src="@/assets/images/icon/fi-rr-redo.svg" alt height="100%" />
                                                        <span class="ml-3  default-text-style">Share</span>
                                                    </b-dropdown-item-button> -->
                                                    <!-- <b-dropdown-item-button v-if="(list.encrypt != 2 && list.encrypt != 7 && list.encrypt != 4)" @click="move(list)">
                                                        <img src="@/assets/images/icon/fi-rr-flag.svg" alt height="100%" />
                                                        <span class="ml-3  default-text-style">Move</span>
                                                    </b-dropdown-item-button> -->
                                                    <!-- <b-dropdown-item-button v-if="(list.encrypt != 2 && list.encrypt != 7 && list.encrypt != 4)" @click="copy(list)" >
                                                        <img src="@/assets/images/icon/fi-rr-copy-alt.svg" alt height="100%" />
                                                        <span class="ml-3  default-text-style">Copy</span>
                                                    </b-dropdown-item-button> -->
                                                    <b-dropdown dropright class="nested-dd-home" id="dropdown-5" v-if="(list.encrypt != 2 && list.encrypt != 7 && list.encrypt != 4)">
                                                    <template #button-content>
                                                        <div class="d-flex justify-content-between" style="width: 136px;">
                                                        <div>
                                                            <img src="@/assets/images/icon/fi-rr-copy-alt.svg" alt height="75%" class="margin-12px"/>
                                                            <span class="ml-3 text-dd-v3">Copy to</span>
                                                        </div>
                                                        <div class="margin-top-dd-bn ml-2">
                                                            <img src="@/assets/images/icon/chevLeft.svg" alt="" class="">
                                                        </div>
                                                        </div>
                                                    </template>
                                                    <b-dropdown-item-button class="copy-file-v3" @click="copyPersonal(list)">
                                                            <img src="@/assets/images/icon/fi-rr-copy-alt.svg" alt height="100%" />
                                                            <span class="ml-3 default-text-style">Personal</span>
                                                    </b-dropdown-item-button>
                                                    <!-- <b-dropdown-item-button class="copy-file-v3" @click="state == 'workgroup' ? copy(list) : copyWorkgroup(list)">
                                                            <img src="@/assets/images/icon/fi-rr-copy-alt.svg" alt height="100%" />
                                                            <span class="ml-3 default-text-style">Workgroup</span>
                                                    </b-dropdown-item-button> -->
                                                    </b-dropdown>
                                                    <b-dropdown-item-button v-if="(list.encrypt != 2 && list.encrypt != 7 && list.encrypt != 4) && idUser == list.added_by" @click="renameItem(list)">
                                                        <img src="@/assets/images/icon/fi-rr-edit.svg" alt height="100%" />
                                                        <span class="ml-3  default-text-style">Rename</span>
                                                    </b-dropdown-item-button>   
                                                    <b-dropdown-item-button @click="deleteFile(list)">
                                                        <img src="@/assets/images/icon/fi-rr-trash-2.svg" alt height="100%" />
                                                        <span class="ml-3  default-text-style">Delete</span>
                                                    </b-dropdown-item-button>
                                                    <!-- <b-dropdown-item-button  @click="openDownload(list)" >
                                                        <img src="@/assets/images/icon/fi-rr-download.svg" alt height="100%" />
                                                        <span class="ml-3  default-text-style">Download</span>
                                                    </b-dropdown-item-button> -->
                                                    <b-dropdown-item-button @click="detailToggle2(list)">
                                                        <img src="@/assets/images/icon/fi-rr-info-2.svg" alt height="100%" />
                                                        <span class="ml-3  default-text-style">Details</span>
                                                    </b-dropdown-item-button>
                                                </b-dropdown>
                                            </div>
                                            <div class="change-opcity" v-else>
                                                <img class="change-opcity ml-3" src="@/assets/images/icon/lock-private-wg.svg" alt="">
                                            </div>
                                        </td>
                                    </tr>
                                    <tr v-if="limit < total">
                                    <td colspan="4">
                                        <div class="d-flex justify-content-center" >
                                            <b-button pill variant="primary" @click="loadmore()"> <i v-if="loading" class="bx bx-loader-alt"></i> Load More</b-button>
                                        </div>
                                    </td>
                                    </tr>
                                </tbody>
                                <tbody v-else-if="listings.length > 0 && !loading">
                                    <!-- {{listings}} -->
                                    <!-- {{ state }} -->
                                    <tr class="item-table" v-for="(list,index) in listings" :key="index" :id="index+'item'" :class="list.checked ? 'active' : ''"
                                    v-on:click.ctrl="ctrlClick(list)"
                                    >
                                    <!-- {{list}} -->
                                        <td>
                                            <b-form-checkbox
                                                :id="index+'checkbox'"
                                                :name="index+'checkbox'"
                                                :class="list.checked == true ? 'more-btn-op-1' : 'more-btn'"
                                                :value="true"
                                                v-model="list.checked"
                                                :unchecked-value="false"
                                                @change="checked(list)"
                                            >
                                            </b-form-checkbox> 
                                        </td>
                                        <td class="align-items-center name-column"
                                            @click.exact="clickers(list)"
                                        >
                                            <div class="td-file" >
                                            <div class="d-flex align-items-center py-2">
                                                <!-- <b-form-checkbox
                                                :id="index+'checkbox'"
                                                :name="index+'checkbox'"
                                                class="more-btn"
                                                :value="true"
                                                v-model="list.checked"
                                                :unchecked-value="false"
                                                @change="checked(list)"
                                                >
                                                </b-form-checkbox>   -->
                                                <div class="d-flex ">
                                                <div class="file-name">
                                                    <!-- {{limit}} {{total}} -->
                                                    <!-- <img v-if="list.isShare" :src="require(`@/assets/images/icon/shared-icon.svg`)" alt> -->
                                                    <img v-if="list.encrypt == 2 || list.encrypt == 7 || list.encrypt == 4" :src="require(`@/assets/images/icon/lock-encrypt.svg`)" alt height="24px"/>
                                                    <img v-else :src="require(`@/assets/images/icon/${list.icon}`)" alt height="24px"/>
                                                    <span v-if="!list.isEdit"  v-b-tooltip.hover.ds1000.top="list.file_name"  class="mx-2">{{list.file_name}}</span>
                                                    <b-form v-else >
                                                        <b-form-group id="input-group-1" class="form-group-rename" label-class="label-input" label-for="input-1">
                                                            <b-input-group>
                                                                <!-- <b-input-group-append>
                                                                    <img :src="require(`@/assets/images/icon/${list.icon}`)" alt />
                                                                </b-input-group-append> -->
                                                                <b-form-input id="input-1" autofocus class="login-input-style" v-model="valueRename" type="text" placeholder="Folder New"></b-form-input>
                                                                <b-input-group-append>
                                                                    <b-button @click="submitRename(index)" class="btn-on-input" v-b-tooltip.hover title="Save" ><i class="dripicons-checkmark text-success"></i></b-button>
                                                                    <b-button @click="rename(index)" class="btn-on-input" v-b-tooltip.hover title="Cancel" ><i class="dripicons-cross text-danger"></i></b-button>
                                                                </b-input-group-append>
                                                            </b-input-group>
                                                        </b-form-group>
                                                    </b-form>
                                                    <!-- <img v-if="list.isShare" :src="require(`@/assets/images/icon/shared-icon.svg`)" alt data-toggle="tooltip" title="This file is shared"> -->
                                                </div>
                                                <!-- <img v-if="list.isShare" :src="require(`@/assets/images/icon/shared-icon.svg`)" alt> -->
                                                <!-- <span v-if="list.encrypt == 2 || list.encrypt == 7 || list.encrypt == 4" class="ml-3 encrypt-text">Encrypted</span> -->
                                                </div>
                                            </div>
                                                <div class="icon-status-file">
                                                <img v-if="list.isShare && state == 'storage'" :src="require(`@/assets/images/icon/shared-file.svg`)" alt v-b-tooltip.hover title="This file is shared" class="ml-2">
                                                <!-- <img v-if="list.isShare" :src="require(`@/assets/images/icon/shared-file.svg`)" alt data-toggle="tooltip" title="This file is shared" class="ml-2"> -->
                                                <!-- <img v-if="list.encrypt == 2 || list.encrypt == 7" :src="require(`@/assets/images/icon/encrypted-file.svg`)" alt data-toggle="tooltip" title="This file is encrypted" class="ml-2"> -->
                                                <img v-if="list.encrypt == 2 || list.encrypt == 7 || list.encrypt == 4" :src="require(`@/assets/images/icon/encrypted-file.svg`)" alt v-b-tooltip.hover title="This file is encrypted" class="ml-2">
                                                </div>
                                            </div>
                                        </td>
                                        <!-- <td class="table-web-display">
                                        <p><span>Encrypt</span></p>
                                        </td> -->
                                        <td @click.exact="clickers(list)"  class="table-web-display">
                                            <p> {{list.datemodified}} </p>
                                        </td>
                                        <td @click.exact="clickers(list)"  class="table-web-display">
                                            <p>{{list.size}}</p>
                                        </td>
                                        <td>
                                            <div v-if="canAccess"
                                                :class="list.checked == true ? 'more-btn-op-1' : 'more-btn'"
                                            >
                                                <b-dropdown id="dropdown-home" dropright class="bg-transparent more-menu">
                                                    <template #button-content>
                                                        <img src="@/assets/images/icon/More.png" @click.exact="clickers(list)" alt />
                                                    </template>
                                                    <b-dropdown-item-button v-if="(list.encrypt != 2) && (list.encrypt != 7)" @click="openFile(list)">
                                                        <img src="@/assets/images/icon/fi-rr-expand.svg" alt height="100%" />
                                                        <span class="ml-3 default-text-style">Open</span>
                                                    </b-dropdown-item-button>
                                                    <b-dropdown-item-button v-if="(!list.isFolder && list.encrypt != 2 && list.encrypt != 7 && list.encrypt != 4) && isOwner" @click="sendInfoEncrypt(list)">
                                                        <img src="@/assets/images/icon/fi-rr-shield-check.svg" alt height="100%" />
                                                        <span class="ml-3 default-text-style">Encrypt</span>
                                                    </b-dropdown-item-button>
                                                    <b-dropdown-item-button v-if="(!list.isFolder && list.encrypt == 2 || list.encrypt == 7) && isOwner" @click="sendInfoDecrypt(list)">
                                                        <img src="@/assets/images/icon/fi-rr-shield-cross.svg" alt height="100%" />
                                                        <span class="ml-3 default-text-style">Decrypt</span>
                                                    </b-dropdown-item-button>
                                                    <b-dropdown-item-button v-if="(!list.isFolder && list.encrypt != 2 && list.encrypt != 7 && list.encrypt != 4 && state != 'workgroup')" @click="shareopennew(list)">
                                                        <img src="@/assets/images/icon/fi-rr-redo.svg" alt height="100%" />
                                                        <span class="ml-3  default-text-style">Share</span>
                                                    </b-dropdown-item-button>
                                                    <!-- <b-dropdown-item-button v-if="(!list.isFolder && list.encrypt != 2 && list.encrypt != 7 && list.encrypt != 4)" @click="shareopen(list)">
                                                        <img src="@/assets/images/icon/fi-rr-redo.svg" alt height="100%" />
                                                        <span class="ml-3  default-text-style">Share</span>
                                                    </b-dropdown-item-button> -->
                                                    <b-dropdown-item-button v-if="(list.encrypt != 2 && list.encrypt != 7 && list.encrypt != 4)" @click="move(list)">
                                                        <img src="@/assets/images/icon/fi-rr-flag.svg" alt height="100%" />
                                                        <span class="ml-3  default-text-style">Move</span>
                                                    </b-dropdown-item-button>
                                                    <!-- <b-dropdown-item-button v-if="(list.encrypt != 2 && list.encrypt != 7 && list.encrypt != 4)" @click="copy(list)" >
                                                        <img src="@/assets/images/icon/fi-rr-copy-alt.svg" alt height="100%" />
                                                        <span class="ml-3  default-text-style">Copy</span>
                                                    </b-dropdown-item-button> -->
                                                    <b-dropdown dropright class="nested-dd-home" id="dropdown-5" v-if="(list.encrypt != 2 && list.encrypt != 7 && list.encrypt != 4)">
                                                    <template #button-content>
                                                        <div class="d-flex justify-content-between" style="width: 136px;">
                                                        <div>
                                                            <img src="@/assets/images/icon/fi-rr-copy-alt.svg" alt height="75%" class="margin-12px"/>
                                                            <span class="ml-3 text-dd-v3">Copy to</span>
                                                        </div>
                                                        <div class="margin-top-dd-bn ml-2">
                                                            <img src="@/assets/images/icon/chevLeft.svg" alt="" class="">
                                                        </div>
                                                        </div>
                                                    </template>
                                                    <b-dropdown-item-button class="copy-file-v3" @click="copyPersonal(list)">
                                                            <img src="@/assets/images/icon/fi-rr-copy-alt.svg" alt height="100%" />
                                                            <span class="ml-3 default-text-style">Personal</span>
                                                    </b-dropdown-item-button>
                                                    <b-dropdown-item-button class="copy-file-v3" @click="state == 'workgroup' ? copy(list) : copyWorkgroup(list)">
                                                            <img src="@/assets/images/icon/fi-rr-copy-alt.svg" alt height="100%" />
                                                            <span class="ml-3 default-text-style">Workgroup</span>
                                                    </b-dropdown-item-button>
                                                    </b-dropdown>
                                                    <b-dropdown-item-button v-if="(list.encrypt != 2 && list.encrypt != 7 && list.encrypt != 4)" @click="renameItem(list)">
                                                        <img src="@/assets/images/icon/fi-rr-edit.svg" alt height="100%" />
                                                        <span class="ml-3  default-text-style">Rename</span>
                                                    </b-dropdown-item-button>   
                                                    <b-dropdown-item-button @click="deleteFile(list)">
                                                        <img src="@/assets/images/icon/fi-rr-trash-2.svg" alt height="100%" />
                                                        <span class="ml-3  default-text-style">Delete</span>
                                                    </b-dropdown-item-button>
                                                    <b-dropdown-item-button  @click="openDownload(list)" >
                                                        <img src="@/assets/images/icon/fi-rr-download.svg" alt height="100%" />
                                                        <span class="ml-3  default-text-style">Download</span>
                                                    </b-dropdown-item-button>
                                                    <b-dropdown-item-button @click="detailToggle2(list)">
                                                        <img src="@/assets/images/icon/fi-rr-info-2.svg" alt height="100%" />
                                                        <span class="ml-3  default-text-style">Details</span>
                                                    </b-dropdown-item-button>
                                                </b-dropdown>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr v-if="limit < total">
                                    <td colspan="4">
                                        <div class="d-flex justify-content-center" >
                                            <b-button pill variant="primary" @click="loadmore()"> <i v-if="loading" class="bx bx-loader-alt"></i> Load More</b-button>
                                        </div>
                                    </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div v-else>
                    <div class="d-flex justify-content-center align-items-center flex-column">
                        <img src="@/assets/images/search-not-found.svg" alt="" class="my-3">
                        <span class="nf-search-title mb-2">No result found</span>
                        <span class="nf-search-sub">We can’t find what you’re looking for.</span>
                        <span class="nf-search-sub">Check your spelling or try more general words.</span>
                    </div>
                </div>
            </div>
            <ModalRename/>
        </Layout>
</template>

<script>
import Layout from '../../layouts/main.vue'
import Helper from '../../../api/helper'
import Sekeleton from "../../../components/widgets/loadingtable";
import Move from "../../../components/widgets/move.vue";
import Copy from "../../../components/widgets/copy.vue";
import CopyPersonal from "../../../components/widgets/copyPersonal.vue";
import CopyWorkgroup from "../../../components/widgets/copyWorkgroup.vue";
import Share from "../../../components/widgets/share.vue";
import Download from "../../../components/widgets/download.vue";
import ImageView from "../../../components/widgets/imageView.vue";
import VideoView from "../../../components/widgets/videoView.vue";
import AudioView from "../../../components/widgets/audioView.vue";
import toTrash from "../../../components/widgets/totrash.vue";
import Encrypt from '../../../components/encrypt.vue'
import Decrypt from '../../../components/decrypt.vue'
import ModalShare from '../../../components/widgets/modalShare.vue'
import ModalRename from '../../../components/widgets/modalRename.vue'

export default {
    components: {
        Layout,
        Sekeleton,
        Move,
        Copy,
        CopyPersonal,
        CopyWorkgroup,
        Share,
        Download,
        ImageView,
        VideoView,
        AudioView,
        toTrash,
        Encrypt,
        Decrypt,
        ModalShare,
        ModalRename
    },
    data() {
        return {
            counter: 0,
            valueRename : "",
            // showProperties : false,
            sort : "ASC",
            allchecked : false,
            item : {isFolder:false},
            isDropdown5Visible: false,
            itemTypes : [
                {
                    type: 'all',
                    label: 'All type',
                    selected: true
                },
                {
                    type: 'folder',
                    label: 'Folder',
                    selected: false
                },
                {
                    type: 'file',
                    label: 'File',
                    selected: false
                },
            ]
        }
    },
    computed: {
        selectedType(){
            return this.itemTypes.filter(v=>v.selected)[0]
        },
        listings: {
            get: function() {
                var items = this.$store.getters[this.$store.getters['state/state']+'/items']
                if(this.selectedType.type == 'folder'){
                    items = items.filter((v)=>{
                        return v.isFolder
                    })
                } else if(this.selectedType.type == 'file'){
                    items = items.filter((v)=>{
                        return !v.isFolder
                    })
                }
                return items
            },
            set: function(newValue) {
                this.$store.commit(this.$store.getters['state/state']+"/SET_ITEMS",newValue);
            }
        },
        detail(){
            return this.$store.getters[this.$store.getters['state/state']+'/item'];
        },
        loading(){
        // return true
            return this.$store.getters[this.$store.getters['state/state']+'/loading'];
        },
        limit(){
            return this.$store.getters[this.$store.getters['state/state']+'/limit'];
        },
        total(){
            return this.$store.getters[this.$store.getters['state/state']+'/totalItem'];
        },
        grid(){
            return this.$store.getters['storage/gridView'];
        },
        selectedField() {
            return this.$store.getters['storage/selected']
        },
        state(){
            return this.$store.getters['state/state'];
        },
        canAccess(){
            var id = this.$store.getters['user/id'];
            var group = this.$store.getters['workgroup/workgroup'];
            var st = this.$store.getters['state/state'];
            if(st == "storage"){
                return true
            } else{
                if(id == group.owner_id){
                    return true;
                }else{
                    return group.access_type != "View";
                }
            }
        },
        isOwner(){
            var id = this.$store.getters['user/id'];
            var group = this.$store.getters['workgroup/workgroup'];
            if(group == null) {
                return true
            } else {
                return id == group.owner_id;
            }
        },
        selectedWorkgroup(){
            return this.$store.getters['workgroup/workgroup']
        },
        idUser(){
            return this.$store.getters['user/id']
        }
    },
    mounted() {
        document.getElementById('inputSearch').focus()
        this.$root.$on('bv::dropdown::show', bvEvent => {
            if(bvEvent.componentId === 'dropdown-5') {
                this.isDropdown5Visible = true;
            }
        })
        this.$root.$on('bv::dropdown::hide', bvEvent => {
            if(bvEvent.componentId === 'dropdown-5') {
                this.isDropdown5Visible = false;
            }
            if(this.isDropdown5Visible) {
                bvEvent.preventDefault()
            }
        })
        this.$store.commit(this.state+"/SET_LIMIT", 100)
        this.$store.dispatch(this.state+"/listing")
    },
    methods: {
        refresh(){
            this.$store.dispatch(this.state+"/listing")
        },
        deleteMultiple(){
            this.$root.$emit('bv::show::modal', 'modaltoTrash');
        },
        changeType(idx){
            this.itemTypes = this.itemTypes.map((v,i)=>{
                return {
                    type: v.type,
                    label: v.label,
                    selected: i == idx
                }
            })
        },
        detailToggle2(item) {
            const sideBarValue = this.$store.getters['storage/sideRightToggle'];
            var showDetails = document.getElementById('show-details-info-sideRight')
            if(!sideBarValue){
                this.$store.dispatch('storage/setSideRightToggle',{isShowing: true, isPersistent: false})
                if(!showDetails.classList.contains('show')){
                    this.$root.$emit('bv::toggle::collapse', 'show-details-info-sideRight')
                }
            } else{
                if(!showDetails.classList.contains('show')){
                    this.$root.$emit('bv::toggle::collapse', 'show-details-info-sideRight')
                }
            }
            this.properties(item)
            this.hideContextMenu()
        },
        clickers(item){
            this.counter++

            if (this.counter === 1) {
            this.clickTimer = setTimeout(() => {
                this.listings = this.listings.map((v)=>{
                    return {
                        ...v,
                        checked : v._id == item._id ? true : false 
                    }
                })
                this.$store.commit("storage/SET_ONE_SELECTED",item)
                this.counter = 0
                this.properties(item)
            }, 300)
            } else if (this.counter === 2) {
            clearTimeout(this.clickTimer)
            this.counter = 0
            this.doubleClick(item)
            }
        
        },
        sorting(){
            this.sort =( this.sort == 'ASC') ? 'DESC' : 'ASC';
            this.$store.dispatch("storage/sorting",this.sort);
            this.hideContextMenu();
        },
        listingList(){
            this.$store.dispatch("storage/listing");
            this.hideContextMenu();
        },
        loadmore(){
            this.$store.commit("storage/SET_LIMIT",this.total);
            this.$store.dispatch("storage/listing");
            this.hideContextMenu();
        },
        doubleClick(item){
            if(!item.isFolder){
            if(item.encrypt != 2 && item.encrypt != 7){
                this.openFile(item);
            } else {
                if(this.canAccess){
                this.sendInfoDecrypt(item)
                }
            }
            }else{
            this.$store.commit(this.state+"/SET_PARENT_ID",item._id);
            // this.$store.commit(this.state+"/SET_BREADCUMB",item);
            this.$store.dispatch(this.state+"/listing")
            }
            this.hideContextMenu();
        },
        renameItem(item){
            if(item == null){item = this.item}
            this.$store.commit(this.state+"/SET_ITEM",item)
            this.$store.dispatch("storage/fileretrieve",{_id : item._id,directory:item.directory})
            .then(()=> {
                this.$root.$emit('bv::show::modal', 'modal-rename-v3')
            })
            this.properties(item)
            this.hideContextMenu()
        },
        rename(index){
            if(index == null){
            var item = this.item
            index = this.listings.findIndex((v)=>{
                return v._id == item._id
            })
            this.valueRename = this.listings[index].isFolder ? this.listings[index].file_name : this.listings[index].file_name.split('.').slice(0, -1).join('.');
            this.$store.commit(this.state+"/SET_RENAME",index);
            this.hideContextMenu();
            } else {
            this.valueRename = this.listings[index].isFolder ? this.listings[index].file_name : this.listings[index].file_name.split('.').slice(0, -1).join('.');
            this.$store.commit(this.state+"/SET_RENAME",index);
            this.hideContextMenu();
            }
        },
        submitRename(index){
            this.valueRename = this.listings[index].isFolder ? this.valueRename : this.valueRename+"."+this.listings[index].file_name.substr(this.listings[index].file_name.lastIndexOf('.') + 1);
            this.$store.dispatch(this.state+"/rename",{id:this.listings[index]._id,file_name:this.valueRename})
            this.$store.commit(this.state+"/SET_RENAME",index);
            this.hideContextMenu();
        },
        move(item){
            if(item == null){item = this.item}
            this.$store.commit(this.state+"/SET_ITEM",item);
            this.$store.commit(this.state+"/SET_HASHMOVE",item.hash_name);
            this.$store.commit(this.state+"/SET_KEYWORD","");
            this.$store.commit(this.state+"/SET_PARENT_ID","");
            this.$store.commit(this.state+"/DELETE_BREADCUMB",[]);
            this.$store.dispatch(this.state+"/listing", 'noclear')
            this.$root.$emit('bv::show::modal', 'modalMove');
            this.hideContextMenu();
        },
        copy(item){
            if(item == null){item = this.item}
            this.$store.commit(this.state+"/SET_ITEM",item);
            this.$store.commit(this.state+"/SET_HASHMOVE",item.hash_name);
            this.$root.$emit('bv::show::modal', 'modalCopy');
            this.hideContextMenu();
        },
        copyPersonal(item){
            if(item == null){item = this.item}
            this.$store.commit("storage/SET_ITEM",item);
            this.$store.commit("storage/SET_HASHMOVE",item.hash_name);
            this.$store.commit("storage/SET_KEYWORD","");
            this.$store.commit("storage/SET_PARENT_ID","");
            this.$store.commit("storage/DELETE_BREADCUMB",[]);
            this.$store.dispatch("storage/listing", 'noclear')
            this.$root.$emit('bv::show::modal', 'modalCopyPersonal');
            this.hideContextMenu();
        },
        copyWorkgroup(item){
            if(item == null){item = this.item}
            this.$store.commit("workgroup/SET_ITEM",item);
            this.$store.commit("workgroup/SET_HASHMOVE",item.hash_name);
            this.$root.$emit('bv::show::modal', 'modalCopyWorkgroup');
        },
        shareopennew(item){
            if(item == null){item=this.item}
            this.$store.commit("storage/SET_ITEM",item);
            this.$store.dispatch("storage/fileretrieve",{_id : item._id,directory:item.directory})
            .then(() => {
            this.$root.$emit('bv::show::modal', 'modal-share-file-v3')
            })
            this.hideContextMenu();
        },
        openDownload(item){
            if(item == null){item=this.item}
            this.$store.commit("storage/SET_ITEM",item);
            this.$store.dispatch("storage/fileretrieve",{_id : item._id,directory:item.directory})
            .then(()=>{
                this.$root.$emit('bv::show::modal', 'modalDownload');
            });
            this.hideContextMenu();
        },
        sendInfoEncrypt(item){
            this.item = item
            this.$root.$emit('bv::show::modal', 'modal-encrypt')
            this.properties(item)
            this.hideContextMenu();
        },
        sendInfoDecrypt(item){
            this.item = item
            this.$root.$emit('bv::show::modal', 'modal-decrypt')
            this.properties(item)
            this.hideContextMenu();
        },
        properties(item){
            if(item == null){item = this.item}
            this.$store.commit("storage/SET_ITEM",item);
            this.$store.commit("storage/SET_SHOW_PROPERTIES",true);
            this.$store.commit("workgroup/SET_SHOW_PARTICIPANTS",false);
            // var header = document.getElementById("page-topbar");
            // header.style.right = "400px"; 
            // var mainpage = document.getElementById("main-page");
            // mainpage.style.marginRight = "410px"; 
            this.$store.dispatch("storage/fileretrieve",{_id : item._id,directory:item.directory});
            this.showProperties = true;
            // this.hideContextMenu();
        },
        deleteFile(item){
            if(item == null){item=this.item}
            this.$store.commit(this.state+"/SET_ITEM",item);
            this.$store.dispatch("storage/fileretrieve",{_id : item._id,directory:item.directory})
            .then(()=>{
                this.$root.$emit('bv::show::modal', 'modaltoTrash');
            });
            this.hideContextMenu();
        },
        openFile(list){
            if(list == null){list=this.item}
            if(list.isFolder){
            this.$store.commit(this.state+"/SET_PARENT_ID",list._id);
            // this.$store.commit(this.state+"/SET_BREADCUMB",list);
            this.$store.dispatch(this.state+"/listing")
            }else{
            if(Helper.supportOnlyOffice(list.extension)){
                if(this.canAccess){
                    localStorage.setItem("access_type", JSON.stringify("edit"))
                }else{
                    localStorage.setItem("access_type", JSON.stringify("view"))
                }
                this.$store.dispatch("storage/open",list);
            }else if(Helper.isImages(list.extension)){
                this.$store.dispatch("storage/getLinkFile",list);
                this.$store.dispatch("storage/fileretrieve",{_id : list._id,directory:list.directory});
                    let imageView = document.getElementById("imageView");
                    imageView.style.display = 'block';
            }else if(Helper.isVideos(list.extension)){
                this.$store.dispatch("storage/fileretrieve",{_id : list._id,directory:list.directory})
                .then(c=>{
                    let video = document.getElementById("VideoView");
                    video.style.display = 'block';
                    this.$store.dispatch("storage/getLinkFile",c)
                    .then((v)=>{
                    let videoPlayer = document.getElementById("videoPlayer");
                    videoPlayer.src = v;
                    videoPlayer.play();
                    });
                });
            }else if(Helper.isAudios(list.extension)){
                this.$store.dispatch("storage/fileretrieve",{_id : list._id,directory:list.directory})
                .then(c=>{
                    let audio = document.getElementById("AudioView");
                    audio.style.display = 'block';
                    this.$store.dispatch("storage/getLinkFile",c)
                    .then((v)=>{
                    let audioPlayer = document.getElementById("audioPlayer");
                    audioPlayer.src = v;
                    audioPlayer.play();
                    });
                });
            }else{
                this.$store.dispatch("storage/download",
                {
                    directory : list.directory,
                    hash_name : list.hash_name,
                    file_name : list.file_name
                }
                );
            }
            }
            this.hideContextMenu();
        },
      changeChecked(){
        if(this.canAccess){
          this.listings = this.listings.map((v)=>{
            return {
              ...v,
              checked : this.allchecked 
            }
          })
          this.$store.commit("storage/SET_ALL_SELECTED",this.allchecked ? this.listings : []);
        }
        // this.hideContextMenu();
      },
      checked(ActiveSelected){
        if(this.canAccess){
          this.$store.commit("storage/SET_SELECTED",ActiveSelected);
          if(ActiveSelected.checked){
            this.properties(ActiveSelected)
            this.hideContextMenu()
          }
          if(this.selectedField.length == this.listings.length){
            this.allchecked = true;
          }else{
            this.allchecked = false;
          }
        }
      },
      ctrlClick(item){
        if(this.canAccess){
          this.listings = this.listings.map((v)=>{
            return {
              ...v,
              checked : v._id == item._id ? !item.checked : v.checked 
            }
          })
          this.$store.commit("storage/SET_SELECTED",item);
        }
      },
      righClick(item){
        if(this.canAccess){
          this.item = item;
          this.$store.commit("storage/SET_ALL_SELECTED",[]);
          this.listings = this.listings.map((v)=>{
            return {
              ...v,
              checked : false 
            }
          })
          var menu = document.getElementById("contextMenu");
          var field = document.getElementById(item._id+"item");
          menu.style.display = 'block';
          menu.style.right = null;
          menu.style.left = field.offsetLeft+field.offsetWidth-60+"px";
          menu.style.bottom = null;
          menu.style.top = field.offsetTop + "px";
        }
      },
      hideContextMenu(){
        var menu = document.getElementById("contextMenu");
        menu.style.display = 'none';
        menu.style.top = 0;
      }
    }
}
</script>

<style scoped>
.search-page{
    /* padding-top: 32px; */
    width: 100%;
    margin-left: -24px;
    padding: 32px 0 0 35px;
}
.title-search-v3{
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -1px;
    color: #262A2C;
    font-weight: 400;
}
.tooltip .tooltip-inner{
  background: #000000 !important;
  color: #fff !important;
}
.nested-dd-home ul{
  transform: translate3d(-173px, 0px, 0px) !important;
}
.margin-12px{
    margin-left: 12px;
}
.margin-top-dd-bn{
    margin-top: 2px;
}
.text-dd-v3{
  color: #262A2C !important;
  font-size: 16px !important;
  font-weight: 300;
  line-height: 20px;
  letter-spacing: -0.6px;
}
.btn-multiple-search{
    color: #262A2C;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -1px;
    background: #E9EAEA;
    border-radius: 4px;
    padding: 8px 16px;
    border: none;
}
.nf-search-title{
    color: #262A2C;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -1px;
}
.nf-search-sub{
    color: #919394;
    font-size: 16px;
    font-weight: 300;
    line-height: 20px;
    letter-spacing: -1px;
}
</style>